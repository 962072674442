import PendingApplications from "../Administration/PendingApplications";

const PwdPendingApplications = ({ role }) => {
  return (
    <>
      <PendingApplications role={role} />
    </>
  );
};

export default PwdPendingApplications;
