import RejectedApplications from "../Administration/RejectedApplications";

const TrafficRejectedApplications = ({ role }) => {
  return (
    <>
      <RejectedApplications role={role} />
    </>
  );
};

export default TrafficRejectedApplications;
