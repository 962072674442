import React, { useState } from "react";
import { server, statusMessage } from "../../common";
import { useSelectAccess } from "../../store/stateFunctions";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { AxiosError } from "axios";
import UserApplicationModal from "./UserApplicationModal";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const RejectedApplications = ({ role }) => {
  const [data, setData] = useState([]);
  const user = useSelectAccess(role);
  const [showModal, setShowModal] = useState(false);
  const [selectedId, setSelectedId] = useState(null);

  const handleShow = (id) => {
    setSelectedId(id);
    setShowModal(true);
  };

  const handleClose = () => setShowModal(false);

  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    getData(currentPage); // Initial data fetch
  }, [currentPage]);

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // Get
  const getData = (page) => {
    server
      .get(`/user_appl/rejected?page=${page}&limit=10`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data.data); // Set the data array correctly
          setTotalPages(response.data.totalPages);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else {
          toast.error(error.response.data.error);
        }
      });
  };

  // search
  const SearchData = (searchValue) => {
    server
      .get(`/user_appl/searchmandalbyid?search=${searchValue}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then(function (response) {
        console.log("api response", response.data);
        if (response.status === 200 || response.status === 201) {
          setData(response.data);
        }
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <>
      <div className="container mt-4">
        <div className="card mb-3">
          <div className="card-header">Search Application</div>
          <div className="card-body row">
            <div className="form-group col-md-5">
              <label>अँप्लिकेशन नंबर/नाव/उत्सव :</label>{" "}
              <input
                type="search"
                className="form-control"
                placeholder="Search "
                onChange={(e) => SearchData(e.target.value)}
              />
            </div>
          </div>
        </div>

        <div className="text-end">
          <ReactHTMLTableToExcel
            className="btn btn-primary"
            id="test-table-xls-button"
            table="totalapplications"
            filename={`Total Applications Report ${new Date()}`}
            sheet="Applications Report"
            buttonText="Export To Excel"
          />
        </div>

        <div className=" mt-4">
          <div className="card mb-3">
            <div className="card-header">Total Application</div>
            <div className="card-body">
              <div className="table-responsive">
                <table
                  id="totalapplications"
                  className="table table-striped table-bordered text-center"
                >
                  <thead className="thead-dark">
                    <tr>
                      <th>Sr.No.</th>
                      <th>Application No.</th>
                      <th>Application Date/Time</th>
                      <th>Festival Name</th>
                      <th>Applicant Name</th>
                      <th>Address</th>
                      <th>Contact No.</th>
                      <th>Mandal Name</th>
                      <th>Mandal Address</th>
                      <th>Permission Status</th>
                      {role !== "Super Admin" && <th>Action</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {data.map((item, index) => {
                      return (
                        <>
                          <tr>
                            <td>{index + 1}</td>
                            <td>{item.id}</td>
                            <td>{item.created_at}</td>
                            <td>{item.festival_name}</td>
                            <td>{item.name}</td>
                            <td>{item.address}</td>
                            <td>{item.mobile}</td>
                            <td>{item.mandal_name}</td>
                            <td>{item.mandal_address}</td>
                            <td>{statusMessage(item, role)}</td>
                            <td>
                              {role !== "Super Admin" && (
                                <button
                                  className="btn btn-primary btn-sm"
                                  onClick={() => handleShow(item.id)}
                                >
                                  View
                                </button>
                              )}
                            </td>
                          </tr>
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>{" "}
          <nav aria-label="Page navigation example">
            <ul className="pagination justify-content-center">
              <li
                className={`page-item ${currentPage === 1 ? "disabled" : ""}`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage - 1)}
                >
                  Previous
                </button>
              </li>

              {Array.from(
                { length: Math.min(10, totalPages) }, // Show up to 10 pages at a time
                (_, index) => {
                  const startPage = Math.max(1, currentPage - 5); // Dynamically calculate start page
                  const page = startPage + index;

                  if (page <= totalPages) {
                    return (
                      <li
                        key={page}
                        className={`page-item ${
                          currentPage === page ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => handlePageChange(page)}
                        >
                          {page}
                        </button>
                      </li>
                    );
                  } else {
                    return null;
                  }
                }
              )}

              <li
                className={`page-item ${
                  currentPage === totalPages ? "disabled" : ""
                }`}
              >
                <button
                  className="page-link"
                  onClick={() => handlePageChange(currentPage + 1)}
                >
                  Next
                </button>
              </li>
            </ul>
          </nav>
        </div>
      </div>

      <UserApplicationModal
        show={showModal}
        handleClose={handleClose}
        id={selectedId}
        role={role}
      />
    </>
  );
};

export default RejectedApplications;
