import ApprovedApplications from "../Administration/ApprovedApplications";

const TrafficApprovedPermissions = ({ role }) => {
  return (
    <>
      <ApprovedApplications role={role} />
    </>
  );
};

export default TrafficApprovedPermissions;
