import React from "react";
import MurticarTotalApptions from "../Administration/MurticarApptions/MurticarTotalApptions";

const PoliceMurticarTotalAppl = ({ role }) => {
  return (
    <div>
      <MurticarTotalApptions role={role} />
    </div>
  );
};

export default PoliceMurticarTotalAppl;
