import React from "react";

const DownLoad = () => {
  return (
    <>
      <div className="p-2 mt-3" style={{ backgroundColor: "#FFA27F" }}>
        <h3 className="container fw-bold align-items-center justify-content-center text-center">
          Download
        </h3>
      </div>
      <div className="container mt-4">
        <div className="card mb-3">
          <div className="card-header">Download Details</div>
          <div className="card-body">
            <table className="table table-bordered table-striped">
              <tbody>
                <tr>
                  <td>1</td>
                  <td className="mt-2">Declaration Form</td>
                  <td>
                    <button className="btn btn-primary">
                      <a
                        className="text-decoration-none text-white"
                        href="/Hamipatra_Festival_Permission.pdf"
                        target="_blank"
                      >
                        Download
                      </a>
                    </button>
                  </td>
                </tr>

                {/* <tr>
                  <td>2</td>
                  <td>Paripatrak 2020</td>
                  <td>
                    <div className="btn btn-primary btn-sm">Download</div>
                  </td>
                </tr>

                <tr>
                  <td>3</td>
                  <td>जाहीर सूचना</td>
                  <td>
                    <div className="btn btn-primary btn-sm">Download</div>
                  </td>
                </tr> */}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownLoad;
