import TotalApplications from "../Administration/TotalApplications";

const PwdTotalApplications = ({ role }) => {
  return (
    <>
      <TotalApplications role={role} />
    </>
  );
};

export default PwdTotalApplications;
