import PendingApplications from "../Administration/PendingApplications";

const PolicePendingApplications = ({ role }) => {
  return (
    <>
      <PendingApplications role={role} />
    </>
  );
};

export default PolicePendingApplications;
