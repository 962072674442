import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import axios, { AxiosError } from "axios";
import { toast } from "react-toastify";
import { useSelectAccess } from "../../store/stateFunctions";
import { server } from "../../common";

const Graphdashboard = ({ role }) => {
  const [date, setDate] = useState([]);
  const [loading, setLoading] = useState(true);
  const [bar, setBar] = useState([]);
  const user = useSelectAccess(role);
  const [chartData, setChartData] = React.useState({ labels: [], values: [] });

  useEffect(() => {
    getData();
    getDashboard();
    getDaatewise();
  }, []);

  const processData = (apiData) => ({
    labels: ["Ganesh Utsav", "Navratri", "Shiv Jayanti", "Other"],
    values: [
      apiData.ganesh_utsavAppl || 0,
      apiData.navratriAppl || 0,
      apiData.shiv_jayantiAppl || 0,
      apiData.otherAppl || 0,
    ],
  });

  const getData = () => {
    server
      .get(`/dashboard/festivalwise`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
      })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          const processedData = processData(response.data);
          setChartData(processedData);
        }
      })
      .catch((error) => {
        toast.error(
          error.response?.data?.message ||
            error.response?.data?.error ||
            "An error occurred"
        );
      });
  };

  const getDashboard = () => {
    server
      .get("/dashboard/divisionwise", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
        params: {
          filters: [],
        },
      })
      .then(function (response) {
        console.log("api response 1", response.data);
        // setDashboard(response.data);
        setBar(response.data);
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  const getDaatewise = () => {
    server
      .get("/dashboard/datewise", {
        headers: {
          "Content-Type": "application/json",
          Authorization: user.authToken,
        },
        params: {
          filters: [],
        },
      })
      .then(function (response) {
        console.log("api response 1", response.data);
        // setDashboard(response.data);
        setDate(response.data);
      })
      .catch(function (error) {
        if (error instanceof AxiosError && error.response?.data?.message)
          toast.error(error.response.data.message);
        else if (error.response?.data?.error) {
          toast.error(error.response.data.error);
        } else toast.error("Failed to connect to server");
      });
  };

  return (
    <>
      <div className=" container row text-center align-item-center mt-3">
        <div className="col-md-6 mt-4">
          <h2>Donut Chart</h2>
          <Chart
            options={{
              chart: {
                type: "donut",
              },
              labels: chartData.labels, // Festival names
              legend: {
                position: "bottom",
              },
              colors: ["#008FFB", "#00E396", "#FEB019", "#FF4560"], // Optional custom colors
            }}
            series={chartData.values} // Application counts
            type="donut"
            height={350}
          />
        </div>{" "}
        <div className="col-md-6">
          <Chart
            type="bar"
            width={550}
            height={500}
            series={[
              {
                name: `Division`,
                data: bar.map((item) => item.department_divisional_office),
                colors: "#539165",
              },
              {
                name: `Ganesh Utsav Applications`,
                data: bar.map((item) => item.totalganesh_utsav),
                colors: "#539165",
              },
              {
                name: `Navratri Applications`,
                data: bar.map((item) => item.totalnavratri),
                colors: "#539165",
              },
              {
                name: ` Shiv JayantiApplications`,
                data: bar.map((item) => item.totalshiv_jayanti),
                colors: "#539165",
              },
              {
                name: `Other Applications`,
                data: bar.map((item) => item.totalother),
                colors: "#539165",
              },
            ]}
            options={{
              title: { text: "Division Wise Applications" },
              labels: bar.map((item) => item.department_divisional_office),
              chart: {
                stacked: true,
              },
              xaxis: {
                title: { text: "Division Wise Applications" },
              },
            }}
          ></Chart>
        </div>
        <div className="container text-center mt-3">
          <div className="col-md-6">
            <Chart
              type="bar"
              // width={550}
              height={500}
              series={[
                {
                  name: `Ganesh Utsav Applications`,
                  data: date.map((item) => item.ganesh_utsav),
                  color: "#539165", // Green for Ganesh Utsav
                },
                {
                  name: `Navratri Applications`,
                  data: date.map((item) => item.navratri),
                  color: "#FF5733", // Orange for Navratri
                },
                {
                  name: `Shiv Jayanti Applications`,
                  data: date.map((item) => item.shiv_jayanti),
                  color: "#C70039", // Red for Shiv Jayanti
                },
                {
                  name: `Other Applications`,
                  data: date.map((item) => item.other),
                  color: "#900C3F", // Dark red for Other
                },
              ]}
              options={{
                title: { text: "Date Wise Applications" },
                labels: date.map((item) => item.year),
                chart: {
                  stacked: true,
                },
                xaxis: {
                  title: { text: "Year" },
                },
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Graphdashboard;
