import RejectedApplications from "../Administration/RejectedApplications";

const PoliceRejectedApplications = ({ role }) => {
  return (
    <>
      <RejectedApplications role={role} />
    </>
  );
};

export default PoliceRejectedApplications;
