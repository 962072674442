import ApprovedApplications from "../Administration/ApprovedApplications";

const DivisionApprovedPermissions = ({ role }) => {
  return (
    <>
      <ApprovedApplications role={role} />
    </>
  );
};

export default DivisionApprovedPermissions;
