import PendingApplications from "../Administration/PendingApplications";

const NagarrachanaPendingAppl = ({ role }) => {
  return (
    <>
      <PendingApplications role={role} />
    </>
  );
};

export default NagarrachanaPendingAppl;
