import ApprovedApplications from "../Administration/ApprovedApplications";

const PoliceApprovedPermissions = ({ role }) => {
  return (
    <>
      <ApprovedApplications role={role} />
    </>
  );
};

export default PoliceApprovedPermissions;
