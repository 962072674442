import TotalApplications from "../Administration/TotalApplications";

const NagarrachanaTotalApplications = ({ role }) => {
  return (
    <>
      <TotalApplications role={role} />
    </>
  );
};

export default NagarrachanaTotalApplications;
