import TotalApplications from "../Administration/TotalApplications";

const PoliceTotalApplications = ({ role }) => {
  return (
    <>
      <TotalApplications role={role} />
    </>
  );
};

export default PoliceTotalApplications;
