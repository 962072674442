import TotalApplications from "../Administration/TotalApplications";

const DivisionTotalApplication = ({ role }) => {
  return (
    <>
      <TotalApplications role={role} />
    </>
  );
};

export default DivisionTotalApplication;
