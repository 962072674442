import PendingApplications from "../Administration/PendingApplications";

const TrafficPendingApplications = ({ role }) => {
  return (
    <>
      <PendingApplications role={role} />
    </>
  );
};

export default TrafficPendingApplications;
